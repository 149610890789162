@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

body {
  font-family: Montserrat, Roboto, "Helvetica Neue", Arial, sans-serif !important;
  color: '#b99a38';
  margin: 0;
  font-size: 1rem;
  background-color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
}

.maindiv {
  padding: 20px;
  /* min-width: 50%; */
}

.question>li {
  padding-top: 20px;
  padding-bottom: 20px;
}

.question li {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

ol {
  padding-left: 15px;
  scroll-snap-type: y mandatory;
}

.dtpicker {
  padding-left: 10px;
}

.map {
  height: 300px;
}

/* .geo {
  position: -ms-align-self: baseline;
  align-self: baseline;
  left:10px;
} */

.geocoords {
  font-size: 8px;
}

.chkoutTotalDiv {
  max-resolution: 30px;
}

.chkouttable {
  max-width: 1000px;
}

/* #appdiv {
  padding: 20px;
} */

#triangle-down {
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 4px solid yellow;
}

.react-date-picker__calendar {
  z-index: 9999 !important;
}

/* .geolookup__suggests-wrapper{
    z-index: 9999 !important;
} */

#download-progress {
  max-width: 200px;
}

.fab {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 9999 !important;
}

.savebtn {
  margin-right: 10px;
}

.toplevelquestion {
  margin-top: 20px;
}

.faqmain h3 {
  margin-top: 30px;
}

.maincontent {
  max-width: 1200px;
  margin: auto;
  min-height: calc(100vh - 400px);
}

.subtxt {
  font-size: 26px;
  font-family: Calibri;
  color: rgb(50, 50, 120);
}

.subcattxt {
  font-size: 18px;
  font-family: Calibri;
  color: rgb(50, 50, 120);
}

.catdesctxt {
  font-size: 16px;
  font-family: Calibri;
  color: rgb(50, 50, 120);
}

.maintxt {
  font-size: 36px;
  font-family: calibri;
  font-weight: bold;
  color: rgb(50, 50, 120);
}

.navactive {
  font-weight: bold;
}

.purchasechk {
  width: 35px;
  height: 25px;
}

.purchasesubcat {
  min-width: 300px;
}

.purchasechkoutdiv {
  /* max-width: 566px; */
  max-width: 700px;
}

.purchasechkoutdivrow {
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 0px;
  align-items: flex-end;
}

.purchasechkoutsubcat {
  text-align: left;
  padding-left: 10px;
}

.purchasechkoutprice {
  margin-left: auto;
}

.purchasechkoutprice label {
  padding-left: 25px;

}

.purchasechkoutpricetrend {
  margin-left: auto;
}

.purchasechkoutpricetrend label {
  padding-left: 25px;
}

.purchasechkoutdescription {
  padding-bottom: 20px;
}

.fixed-bottom {
  /* position: fixed; */
  right: 0;
  left: 0;
  z-index: 1030;
  bottom: 0;
}

.top-cantainer {
  height: 160px;
  width: 100%;
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .maintxt {
    font-size: 20px;
  }

  .subtxt {
    font-size: 14px;
  }

  .purchasechk {
    width: 25px;
    height: 25px;
  }

  .purchasechkoutpricetrend {
    font-size: 9px;
  }

  .purchasechkoutpricesample {
    /* font-size: 9px; */
  }
}

@media only screen and (max-width: 510px) {
  .protiptxt {
    font-size: 6px;    
  }
}

@media only screen and (min-width: 510px) {
  .protiptxt {
    margin-top: 10px;
  }
}
/*
.quizprogressdesktop {
    margin: 0px;
    top: 145px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: fixed;
    z-index: 9999 !important;
    width:  170px;
    height: 170px;
}
@media only screen and (max-width: 800px) {
  .quizprogressdesktop {
      margin: 0px;
      top: 130px;
      right: 20px;
      bottom: auto;
      left: auto;
      position: fixed;
      z-index: 9999 !important;
      width:  70px;
      height: 70px;
  }
}*/

.quizprogressdesktop {
  /* margin-right: 10px;
    margin-top: 5px;
    top: 0;
    float: right;
    position: -webkit-sticky; /* Safari *
    position: sticky;
    z-index: 9999 !important;
    width:  170px;
    height: 170px;
    margin-right: -30%; */

  margin-right: 10px;
  margin-top: 160px;
  top: 100px;
  z-index: 1;

  float: right;
  position: -webkit-sticky;
  position: sticky;
  width: 170px;
  height: 170px;
  /*top: 200px;
    right: 50px;
    position: fixed; */
}

.rs-picker-daterange-panel {
  z-index: 9999 !important;
}

.react-datepicker__close-icon {
  right: -24px !important;

}

.react-datepicker__close-icon::after {
  background: #b99a38;
  background: linear-gradient(90deg, #b99a38, #dfc779);
}

.react-datepicker__day--highlighted {
  background-color: #7eff89 !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #3dcc4a !important;
}


.geolookup__button {
  /* background: blue;
    border-radius: 4px;
    color: #fff; */
  background: #fff;
  color: #b99a38;
  border: 2px solid #b99a38;
  /* padding: 8px; */
  padding: 5px 5px 7px;
  border-left: 0;
}

.fabbutton {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 9999 !important;
}



@media only screen and (max-width: 800px) {
  .quizprogressdesktop {
    margin-right: 10px;
    margin-top: 0px;
    top: 80px;
    float: right;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    z-index: 9999 !important;
    width: 50px;
    height: 50px;
  }

  .fabbutton {
    width: 5px;
    height: 5px;
  }

  .p--30 {
    padding: 30px 0 !important;
  }
}

.toplevelquestion {
  min-height: 100vh;
}

.toplevelquestion_none {
  min-height: calc(100vh / 2);
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

.full-width {
  flex: 1
}

.text-center {
  text-align: center;
}

.make-me-gradiant {
  /* background: linear-gradient(90deg, #b99a38, #dfc779) !important; */
}

.answer-snap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  text-decoration: none;
  border: none;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
}

.answer-snap:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}



.answer-label {
  display: flex !important;
  padding: 75px 110px;
  margin: 0 !important;
  cursor: pointer;
}

.answer-text {
  min-width: 200px;
  color: #fff;
  width: 200px;
}

.answer-checkbox {
  position: absolute !important;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  visibility: hidden;
}

.self-assessment .header {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 39px;
  line-height: 48px;
  border-top: 1px solid #b99a38;
  border-bottom: 1px solid #b99a38;
  border-color: linear-gradient(90deg, #b99a38, #dfc779);
  padding: 8px 0;
  text-align: center;
}

.selected-answer {
  width: 300px;
  height: 200px;
  border: 15px solid coral;
  animation: mymove 5s infinite;
}

@keyframes mymove {
  50% {
    border-color: lightblue;
  }
}

/* .answer-label {
  text-decoration: none;
  border: none;
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
}
/* Adding transformation when the button is active *

.answer-label:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size *
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow *
} */

.landing-page .act-now__btn {
  position: absolute;
  bottom: 155px;
  left: 76px;
  padding: 0;
  width: 400px;
  height: 130px;
  background: #c9af5c;
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  text-transform: capitalize;
}

.landing-page img#sec1 {
  /* margin-top: 0;
  padding: 80px; */
}

.flex-acjc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.ac {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.f-row {
  flex-direction: row;
}

.f-column {
  flex-direction: column;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.m-full-width {
  min-width: 100%;
}

.mr-auto {
  margin-right: auto;
}

.section .heading {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 39px;
  line-height: 47px;
  color: #6D6D6D;
  width: 370px;
  margin-bottom: 40px;
}

.section .sub-heading {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin: 0 auto;
  width: 300px;
  margin: 16px auto;
  text-align: center;
}

.heading-title {
  /* font-family: 'Inter'; */
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.sec6-heading {
  /* font-family: 'Inter'; */
  font-weight: 600;
  font-size: 39px;
  line-height: 47px;
  color: #6D6D6D;
  text-align: center;
}

.f-18 span {
  font-size: 18px;
}

.read-more {
  position: absolute !important;
  bottom: 0;
}

video {
  outline: none;
}

.video-js .vjs-tech {
  top: -2px;
}

.video-js {
  overflow: hidden;
}

#section1 {
  transform: scale(1.2);
}

.align-footer {
  padding: 0 8px !important;
}



@keyframes actNowAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* The element to apply the animation to */
.act-now {
  opacity: 0;
}

.make-visible {
  opacity: 1;
  animation-name: actNowAnimation;
  animation-timing-function: ease-in-out;
  animation-duration: 4s;
  animation-fill-mode: both;

}

.sec1-card video {
  margin-top: -18px;
}

.small-section .sec6-heading:not(:first-child) {
  font-size: 27px !important;
  margin-bottom: 8px !important;
}


@media screen and (max-width: 1500px) {
  #heading-know-your {
    font-size: 30px;
  }
}


@media only screen and (max-width: 1366px) and (min-width: 1050px) {
  #sec6 > div {
    margin: 0 auto !important;
  }

  .sec6-heading {
    font-size: 30px !important;
    margin-bottom: 8px !important;
  }

  .sec1-card {
    margin-top: -50px !important;
  }

  .sec1-card video {
    margin-top: -70px !important;
  }

  .sec1-card .sec1-text--main {
    top: 18% !important;
  }

  .sec1-card .sec1-text-container {
    max-width: 380px !important
  }

  /* .sec1-card .sec1-text-container .know-your {
    margin: 90px 0px 10px !important;
  } */

  .sec1-card .sec1-text-container .act-now {
    width: 200px !important;
    max-height: 60px !important;
    font-size: 30px !important;
    margin: 0 !important;
  }

  .sec1-card .sec1-text-container p {
    font-size: 19px !important;
  }
}



@media only screen and (max-width: 1250px) and (min-width: 1050px) {
  #buddy-now-logo {
    width: 150px;
  }

  .self-assessment--content {
    padding: 40px !important;
  }

  .react-datepicker__input-container .MuiTypography-subtitle2 {
    font-size: 11px;
  }

  .m-md--none {
    margin: 0 !important;
  }

  .f-md {
    font-size: 14px !important;
  }

  .whats-new {
    margin-bottom: 18px !important;
    margin-right: 20px !important;
  }

  #section1 {
    transform: scale(1);
  }

  .report-pur-card {
    margin: 12px 80px 60px !important;
  }

  .chev {
    width: 50px !important;
    height: 50px !important;
    bottom: 85px !important;
  }

  .answer-text {
    min-width: 165px !important;
    width: 165px !important;
  }
  .maintenance .clock {
    font-size: 40px !important;
  }
  .maintenance .header {
    font-size: 27px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .quizprogressdesktop {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1050px) {
  .root-container {
    max-width: 1030px !important;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .sec1-text-container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto !important;

  }
  .sec1-text--main {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    padding: 40px !important;
  }
  #section1 {
    transform: scale(1);
  }
  .act-now {
    opacity: 1 !important;
    width: 200px !important;
    max-height: 60px !important;
    font-size: 30px !important;
    margin: 0 !important;
  }
  .know-your {
    margin: 20px 0px 10px !important;
  }
  .sec2--container {
    padding: 30px !important;
  }
  .sec2--container .MuiGrid-container {
    width: 100% !important;
    margin: 0 !important;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item {
    padding: 0 !important;
    width: 100% !important;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item .section {
    padding: 0 !important;
    /* width: 100% !important; */
    width: 420px;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item .section {
    padding: 0 !important;
    /* width: 100% !important; */
    width: 420px;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item .section .heading {
    font-size: 27px !important;
    width: 100%  !important;
  }
  .small-section {
    padding: 0 !important;
    margin: 0 !important;
  }
  .small-section .sec6-heading {
    font-size: 19px !important;
    line-height: 25px !important;
    margin: 0 !important;
  }
  .MuiContainer-root {
    max-width: 900px !important;
  }
  .capcha-img {
    width: 140px;
  }

  .order-place {
    width: 75% !important;
  }
  .report-pur-card {

    margin: 12px 12px 60px !important;

  }
  .chev {
    width: 50px !important;
    height: 50px !important;
    bottom: 85px !important;
  }
  .select-ai--report {
    font-size: 24px !important;
  }
  .hide--sm {
    display: none !important;
  }

  .show--sm {
    display: inline-block !important;
  }
  .self-assessment--content {
    padding: 16px !important;
  }
  .calender-btn {
    max-width: 100px !important;
  }
  /* .grid-item {
    max-width: 225px !important;
    margin: 0 auto !important;
  } */
  /* .grid-item .answer-snap {
    background-size: contain !important;
  } */
  .react-datepicker__input-container .MuiTypography-subtitle2 {
    font-size: 11px;
  }
  .stack-container  .answer-text {
    font-size: 11px !important;
  }
  .stack-container .small-block .answer-text {
    min-width: 100px !important;
    width: 100px !important;
    word-break: break-word !important;
  }

  .stack-container .big-block .answer-text {
    min-width: 180px !important;
    width: 180px !important;
    word-break: break-word !important;
  }


  .MuiTypography-subtitle1 {
    font-size: 14px !important;
  }
  .MuiTypography-h5 {
    font-size: 19px !important;
  }
  .answer-snap .MuiTypography-subtitle1 {
    font-size: 11px !important;
    min-width: 180px !important;
    width: 180px !important;
    word-break: break-word !important;
  }
  .grid-item .answer-snap .answer-label {
    padding: 50px 80px !important;
  }
  .grid-item--container {
    max-width: 225px !important;
    margin: 0 auto !important;
  }
  .chev {
    display: none !important;
  }
  .video-section {
    padding: 12px !important;
  }
  .video-section video {
    width: 100% !important;
  }

  #sec7 .sec6-heading:first-child {
    font-size: 27px !important;
  }
  .small-section .sec6-heading:not(:first-child) {
    font-size: 24px !important;
    margin-bottom: 8px !important;
  }
  .top-cantainer {
    height: 100px !important;
  }
  .maintenance .header {
    font-size: 27px !important;
  }
  .no-m--sm {
    margin: 0 !important;
  }
  /* .no-m--sm {
    margin: 0 !important;
  }

  .maintenance .header {
    font-size: 23px !important;
    line-height: 28px ;
  }

  .maintenance .subHeader {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px;
  }

  .maintenance .subDesc {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  .maintenance .clock {
    font-weight: 400;
    font-size: 30px !important;
    line-height: 44px;
  }
  .maintenance .customer-testimonials {
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
  } */
  .maintenance > div {
    height: 100vh;
  }
}
@media only screen and (max-width: 1049px) and (min-width: 870px) {
  #section1 {
    transform: scale(1);
  }
  #sec1 {
    max-width: 900px !important;
  }
  .sec1-card .sec1-text-container .act-now {
    width: 200px !important;
    max-height: 60px !important;
    font-size: 30px !important;
    margin: 0 !important;
}
  /* .sec1-card .sec1-text-container .know-your {
    margin: 80px 0px 10px !important;
  } */
  .report-pur-card {
    margin: 12px 80px 60px !important;
  }
  .chev {
    display: none !important;
  }
  .date-na h6 {
    font-size: 16px !important;
  }
  .self-assessment--content {
    padding: 40px !important;
  }
  .grid-item {
    height: 115px !important;
  }
  .grid-item .answer-snap {
    background-size: contain !important;
  }
  .react-datepicker__input-container .MuiTypography-subtitle2 {
    font-size: 11px;
  }
  .stack-container  .answer-text {
    font-size: 11px !important;
  }
  .stack-container .small-block .answer-text {
    min-width: 100px !important;
    width: 100px !important;
    word-break: break-word !important;
  }

  .stack-container .big-block .answer-text {
    min-width: 180px !important;
    width: 180px !important;
    word-break: break-word !important;
  }





  .grid-item .answer-snap .answer-label {
    padding: 50px 80px !important;
  }

  .chev {
    width: 50px !important;
    height: 50px !important;
    bottom: 85px !important;
  }
  #buddy-now-logo {
    width: 120px;
    margin-bottom: 8px;
  }

  .m-md--none {
    margin: 0 !important;
  }

  .f-md {
    font-size: 14px !important;
  }

  .whats-new {
    margin-bottom: 18px !important;
    margin-right: 20px !important;
  }
  .whats-new button {
    padding: 8px 10px;
  }

  .MuiTypography-subtitle1 {
    font-size: 14px !important;
  }
  .MuiTypography-h5 {
    font-size: 19px !important;
  }
  .answer-snap .MuiTypography-subtitle1 {
    font-size: 11px !important;
    min-width: 170px !important;
    width: 170px !important;
    word-break: break-word !important;
  }
  /* .grid-container .answer-text {
    min-width: 150px !important;
    width: 150px !important;
    word-break: break-word !important;
  } */
  .grid-item--container {
    max-width: 25% !important;
  }
  .maintenance .clock {
    font-size: 40px !important;
  }
  .maintenance .header {
    font-size: 27px !important;
  }
}

@media only screen and (max-width: 600px) {
  #section1 {
    transform: scale(1);
  }
  .sec1-text-container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 auto !important;

  }
  .sec1-text--main {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    padding: 35px !important;
  }
  .act-now {
    opacity: 1 !important;
    width: 135px !important;
    max-height: 40px !important;
    font-size: 17px !important;
    margin: 0 !important;
  }
  .know-your {
    margin: 20px 0px 10px !important;
  }
  .sec2--container {
    padding: 30px !important;
  }
  .sec2--container .MuiGrid-container {
    width: 100% !important;
    margin: 0 !important;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item {
    padding: 0 !important;
    width: 100% !important;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item .section {
    padding: 0 !important;
    width: 100% !important;
  }
  .sec2--container .MuiGrid-container .MuiGrid-item .section .heading {
    font-size: 27px !important;
  }
  .MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .chev {
    display: none !important;
  }
  .purcharse-content {
    padding: 8px !important;
  }

  .discounted-price{
    display: flex;
    flex-direction: column;
  }

  .make-portrait {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .MuiCardHeader-title, .header {
    font-size: 19px !important;
  }

  .MuiTypography-subtitle1, .MuiTypography-body1 {
    font-size: 14px !important;
  }

  .search-input {
    width: 100% !important;
  }

  .order-place {
    width: 75% !important;
  }
  .grid-item {
    height: 110px !important;
  }
  .grid-item--container {
    max-width: 175px !important;
  }
  .grid-item .answer-snap {
    background-size: contain !important;
  }

  .report-table th,
  .report-table td {
    text-align: left;
    padding: 16px 4px !important;
    padding-right: 2px !important;
    font-size: 14px !important;
  }
  .report-pur-card {

    margin: 12px 80px 60px !important;

  }
  .select-ai--report {
    font-size: 19px !important;
  }
  .hide--sm {
    display: none !important;
  }

  .show--sm {
    display: inline-block !important;
  }
  .chev {
    width: 35px !important;
    height: 35px !important;
    bottom: 240px !important;
  }
  .chev svg {
    font-size: 25px !important;
  }

  header > div {
    padding: 0 24px !important;
  }


  .assessment-error {
    padding-left: 60px !important;
    width: 100% !important;
  }

  .MuiGrid-item h5 {
    font-size: 18px !important;
  }
  .MuiGrid-item h6 {
    font-size: 12px !important
  }

  /* .grid-item--container {
    max-width: 50% !important;
  } */

  .self-assessment--content {
    padding: 8px !important;
  }
  .date-options {
    display: flex !important;
    flex-direction: column !important;
    position: relative !important;
  }
  .date-sub-question {
    max-width: 75% !important;
  }
  .date-na {
    position: absolute !important;
    right: 0 !important;
  }
  .date-na h6 {
    font-size: 16px !important;
  }
  .date-calender {

  }
  .add-more--questions {
    width: 70% !important;
  }
  .big-block {
    max-width: 200px !important;
  }
  .big-block h6 {
    min-width: 150px !important;
    width: 150px !important;
  }
  .answer-text  {
    min-width: 150px !important;
    width: 150px !important;
  }
  .buynow-btn {
    max-width: 260px !important;
  }
  .buynow-circle {
    transform: translateX(-125%) !important;
  }

  .aboutus-page h1 {
    font-size: 29px !important;
  }
  .aboutus-page .aboutus-header {
    font-size: 21px !important;
  }
  .hide--xs {
    display: none !important;
  }
  .show--xs {
    display: inline-block !important;
  }
  .w--100 {
    width: 100% !important;
  }

  .anonymous {
    padding: 8px 40px;
  }
  .sec1-card .sec1-text-container h1 {
    font-size: 27px !important;
  }
  .small-section {
    padding: 0 !important;
    margin: 0 !important;
  }
  .small-section .sec6-heading {
    font-size: 15px !important;
    line-height: 25px !important;
    margin: 0 !important;
  }
  .video-section {
    padding: 12px !important;
  }
  .video-section video {
    width: 100% !important;
  }
  #demo-customized-menu .MuiPaper-root {
    min-width: 100vw !important;
    height: calc(100vh - 72px) !important;
    margin-left: -15px !important;
    margin-top: 0px !important;
    border-radius: 0 !important;
    top: 72px !important;
  }
  #demo-customized-menu .MuiPaper-root ul {
    padding: 0 !important;
  }
  #demo-customized-menu .MuiPaper-roott a {
    font-size: 19px !important;
  }
  .faqmain h3 {
    font-size: 21px !important;
  }
  .faqmain h2 {
    font-size: 25px !important;
  }
  #appdiv > .MuiContainer-root, .landing-page {
    margin-top: 100px !important;
  }
  .react-datepicker__month-container {
    float: none !important;
  }
  .question li {
    list-style: none !important;
  }
  .home-sec {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  .home-sec--bottomsec {
    min-height: 450px !important;
    max-height: 450px !important;
  }
  .faqmain .faq-content--section {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  .self-assessment--btn {
    font-size: 17px !important;
    margin: 0 !important;
  }

  #sec7 .sec6-heading:first-child {
    font-size: 24px !important;
    margin-bottom: 12px !important;
  }
  .small-section .sec6-heading:not(:first-child) {
    font-size: 15px !important;
    margin-bottom: 8px !important;
  }
  .top-cantainer {
    height: 100px !important;
  }
  .no-m--sm {
    margin: 0 !important;
  }
  .maintenance .header {
    font-size: 23px !important;
    line-height: 28px ;
  }
  .maintenance .subHeader {
    font-weight: 600;
    font-size: 18px !important;
    line-height: 22px;
  }
  .maintenance .subDesc {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
  .maintenance .clock {
    font-weight: 400;
    font-size: 30px !important;
    line-height: 44px;
  }
  .maintenance .customer-testimonials {
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
  }
  .maintenance > div {
    height: 100vh;
  }
}

@media only screen and (max-width: 600px) and (min-width: 510px){
  .grid-item--container {
    max-width: 180px !important;
  }
}
@media only screen and (max-width: 470px){
  .grid-item--container {
    max-width: 180px !important;
  }
.answer-label {
  padding: 50px 70px !important;
}
.big-block, .small-block {
  max-width: 175px !important;
}
}
